(function webpackUniversalModuleDefinition(root, factory) {
	//React datetime
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("moment"), require("react-dom"));
	//React datetime
	else if(typeof define === 'function' && define.amd)
		define(["react", "moment", "react-dom"], factory);
	//React datetime
	else if(typeof exports === 'object')
		exports["Datetime"] = factory(require("react"), require("moment"), require("react-dom"));
	//React datetime
	else
		root["Datetime"] = factory(root["react"], root["moment"], root["react-dom"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 